<template>
  <div class="tab_bar">
    <van-tabbar v-model="isActive">
      <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="search" to="/My">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      isActive: 0,
    };
  },
  components: {},
  mounted() {
    this.isActive = this.active;
  },

  props: {
    active: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
};
</script>

<style scoped lang="less">
.tab_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 0.98rem; */
  background: #ffffff;
}

/deep/ .van-tabbar {
  height: 0.98rem;
}

/deep/ .van-icon-home-o::before {
  background-image: url("../../assets/images/home.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  width: 0.45rem;
  height: 0.45rem;
}

/deep/ .van-icon-search::before {
  background-image: url("../../assets/images/my.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  width: 0.45rem;
  height: 0.45rem;
}

/deep/ .van-tabbar-item--active {
  color: #000000;
}

/deep/ .van-tabbar-item {
  color: #999999;
}

/deep/ .van-tabbar-item--active .van-icon-home-o::before {
  background-image: url("../../assets/images/home_active.png");
}

/deep/ .van-tabbar-item--active .van-icon-search::before {
  background-image: url("../../assets/images/my_active.png");
}
</style>
