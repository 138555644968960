<template>
  <div class="picture">
    <div
      class="item"
      @click="handelItem(item.id)"
      v-for="(item, index) of list"
      :key="index"
    >
      <img :src="item.src" alt="" />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      list: [
        {
          src: require("@/assets/images/home_img01.png"),
          id: 11000000,
        },
        {
          src: require("@/assets/images/home_img02.png"),
          id: 12000000,
        },
        {
          src: require("@/assets/images/home_img03.png"),
          id: 13000000,
        },
        {
          src: require("@/assets/images/home_img04.png"),
          id: 14000000,
        },
        {
          src: require("@/assets/images/home_img05.png"),
          id: 15000000,
        },{
          src: require("@/assets/images/home_img06.png"),
          id: 16000000,
        },
      ],
    };
  },
  components: {},
  methods: {
    // 点击item
    handelItem(id) {
      this.$router.push({path: '/PersonalityAssessment', query: {id: id}})
      // this.$router.push({name: 'PersonalityAssessment', params: {id: id}})
    },
  },
};
</script>

<style scoped lang="less">
.picture {
  width: 100%;
  padding: 0 0.25rem;

  .item {
    margin-bottom: 0.25rem;

    img {
      width: 100%;
      height: 2.2rem;
    }
  }

  .item:last-child {
    margin-bottom: 0;
  }
}
</style>
