<template>
  <div class="banner">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) of list" :key="index">
        <img :src="item.src" alt="" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {
      list: [
        {
          src: require("@/assets/images/banner01.png"),
        },
        {
          src: require("@/assets/images/banner02.png"),
        },
        {
          src: require("@/assets/images/banner03.png"),
        },
      ],
    };
  },
  components: {},
};
</script>

<style scoped lang="less">
.banner {
  width: 100%;
  height: 3.2rem;
  margin-bottom: 0.3rem;
  img {
    width: 100%;
    height: 3.2rem;
  }
}

/deep/ .van-swipe__indicator--active {
  width: 0.28rem !important;
  border-radius: 0.05rem !important;
}
</style>