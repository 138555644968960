<template>
  <div class="home" id="home">
    <navigationBar :showBack="showBack" :home="home">首页</navigationBar>
    <banner></banner>
    <evaluationPictureIndex></evaluationPictureIndex>
    <tabBar :active="0"></tabBar>
  </div>
</template>

<script>
import navigationBar from "@/components/navigationBar/navigationBar";
import banner from "@/components/banner/banner";
import evaluationPictureIndex from "@/components/evaluationPictureIndex/evaluationPictureIndex";
import tabBar from "@/components/tabBar/tabBar";
import { getLoginToken } from "@/request/api";
import { mapMutations } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      showBack: false,
      loginUsers: {},
      token: "",
      retUrl: "",
      home: "home"
    };
  },
  components: {
    navigationBar,
    banner,
    evaluationPictureIndex,
    tabBar,
  },
  created() {
    this.token = this.$route.query.token;
    this.retUrl = localStorage.getItem('retUrl');
    this.showBack = this.retUrl ? true : false
    if (this.token) {
      let param = {
        token: this.token,
      };
      this.getLoginToken(param);
    } else {
      this.loginUsers = localStorage.getItem("loginUser")
        ? JSON.parse(localStorage.getItem("loginUser"))
        : "";
      if (this.loginUsers.institutionId == 0) {
        this.$router.push("/perfectInfo");
      }
    }
  },

  methods: {
    ...mapMutations(["changeLogin", "loginUser"]),
    getLoginToken(param) {
      getLoginToken(param).then((res) => {
        console.log(res);
        if(res.token) {
          this.changeLogin(res.token);
          this.loginUser(JSON.stringify(res.loginUser));
          localStorage.setItem('retUrl', res.retUrl);
        }
        
      });
    },
  },
};
</script>

<style scoped>
.home {
  min-height: calc(100vh - 0.88rem - 0.98rem);
  margin-top: 0.88rem;
  padding: 0 0 0.98rem 0;
}
</style>
